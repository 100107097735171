// 防抖
export function _debounce(fn, delay) {
    var delay2 = delay || 3000;
    var timer;
    console.log(1111)
    return function () {
        var th = this;
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(function () {
            timer = null;
            fn.apply(th, args);
        }, delay2);
    };
}

// 节流
export function _throttle(fn, interval) {
    var last;
    var timer;
    var interval2 = interval || 100;
    return function () {
        var th = this;
        var args = arguments;
        var now = +new Date();
        if (last && now - last < interval2) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                fn.apply(th, args);
            }, interval2);
        } else {
            last = now;
            fn.apply(th, args);
        }
    }
}