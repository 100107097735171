<template>
  <div class="TalentConcept">
    <div class="bgimg">
      <img src="../../assets/img/Concept_bgimg.png" alt />
      <div class="aboutUs_tab">
        <div :class="[tab==0?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(0)">人才招聘</div>
        <div :class="[tab==1?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(1)">联系方式</div>
        <div :class="[tab==2?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(2)">意见反馈</div>
        <div :class="[tab==3?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(3)">项目合作</div>
        <div :class="[tab==4?'aboutUs_tab_item2':'aboutUs_tab_item']" @click="choosebtn(4)">供应商报名</div>
      </div>
    </div>

    <div v-if="tab==0" class="TalentConcept">
      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">人才理念</div>
        <div>Talent Concept</div>
      </div>

      <div class="TalentConcept_content">
        <el-row class="TalentConcept_content_style">
          <el-col :span="25">
            <div class="TalentConcept_item">
              <div style="display:table-cell;vertical-align: middle;">
                <div class="TalentConcept_item_title">庸才</div>
                <div class="TalentConcept_item_text">
                  <p>既不能发现问题</p>
                  <p>也不能解决问题</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="25" class="TalentConcept_content_style2">
            <div class="TalentConcept_item">
              <div style="display:table-cell;vertical-align: middle;">
                <div class="TalentConcept_item_title">普才</div>
                <div class="TalentConcept_item_text">
                  <p>只能发现问题</p>
                  <p>但想不到解决办法</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="25" class="TalentConcept_content_style2">
            <div class="TalentConcept_item">
              <div style="display:table-cell;vertical-align: middle;">
                <div class="TalentConcept_item_title">人才</div>
                <div class="TalentConcept_item_text">
                  <p>既能发现问题</p>
                  <p>也能解决问题</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="25" class="TalentConcept_content_style2">
            <div class="TalentConcept_item">
              <div style="display:table-cell;vertical-align: middle;">
                <div class="TalentConcept_item_title">优秀人才</div>
                <div class="TalentConcept_item_text">
                  <p>能预判问题，提前</p>
                  <p>做好方案并落实执行</p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="25" class="TalentConcept_content_style2">
            <div class="TalentConcept_item">
              <div style="display:table-cell;vertical-align: middle;">
                <div class="TalentConcept_item_title">杰出人才</div>
                <div class="TalentConcept_item_text">
                  <p>能洞察发展趋势</p>
                  <p>优化工作方法</p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">招聘岗位</div>
        <div>Recruitment</div>
      </div>

      <div class="TalentConcept_content">
        <el-row>
          <el-col :span="8" class="TalentConcept_content_style" v-for="item in reData" :key="item">
            <div class="TalentConcept_content_item2">
              <div class="TalentConcept_content_item2_line"></div>
              <div class="TalentConcept_content_item2_content">
                <div
                  class="TalentConcept_content_item2_content_style"
                  style="font-family: Source Han Sans CN, Source Han Sans CN-Medium;font-weight: 600;color: #333333;"
                >
                  <div
                    class="TalentConcept_content_item2_content_style_content"
                  >{{item.recruitName}}</div>
                  <span>{{item.recruitNum}}人</span>
                  <button
                    class="TalentConcept_content_item2_content_btn"
                    @click="openRecruitment(item)"
                  >投递简历</button>
                </div>
                <div
                  class="TalentConcept_content_item2_content_style2"
                  style="font-family: Source Han Sans CN, Source Han Sans CN-Normal;color: #666666;"
                >{{item.recruitWorkplace}}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <el-dialog
        title="请选择投递简历网站"
        :visible.sync="centerDialogVisible"
        top="36vh"
        width="30%"
        center
      >
        <el-radio-group v-model="radioRecruitment">
          <el-radio
            v-for="(item , index) in RecruitmentItem"
            :key="index"
            :label="item.platformId"
            @change="radioChange"
          >
            <img
              class="radioImg"
              v-if="item.platformName=='前程无忧'"
              src="../../assets/img/qcwy.png"
              alt
            />
            <img
              class="radioImg"
              v-if="item.platformName=='智联招聘'"
              src="../../assets/img/zhilian.png"
              alt
            />
            <img
              class="radioImg"
              v-if="item.platformName=='58同城'"
              src="../../assets/img/58@2x.png"
              alt
            />
            <img
              class="radioImg"
              v-if="item.platformName=='拉钩'"
              src="../../assets/img/lagou.png"
              alt
            />
            <img
              class="radioImg"
              v-if="item.platformName=='BOSS直聘'"
              src="../../assets/img/BOSS.png"
              alt
            />
          </el-radio>
        </el-radio-group>
        <span slot="footer" class="dialog-footer">
          <button class="radioBtn" @click="togoLink()">投递简历</button>
        </span>
      </el-dialog>
    </div>
    <div v-if="tab==1" class="ContactUs">
      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">联系方式</div>
        <div>Contact Us</div>
      </div>

      <div class="ContactUs_bgimg">
        <img src="../../assets/img/Contact_bgimg.png" alt />
        <!-- <div class="ContactUs_Popconfirm"></div> -->
        <div class="bubble-box arrow-bottom">
          <div class="wrap">
            湖北省武汉市江岸区新华路296号IFC金融中心41层
            <p>Tel:027 - 85803033</p>
          </div>
        </div>
        <div class="ContactUs_content">
          <div class="ContactUs_content_content">
            <div class="ContactUs_content_title">亿事达（商业）运营管理有限公司</div>
            <div
              class="ContactUs_content_title2"
            >Essida (Commercial) Operations Management Co., Ltd.</div>
            <div class="ContactUs_content_text">
              <p>咨询热线：027 - 85803033</p>
              <p>
                公司邮箱：
                <a href="http://mail.163.com" target="_blank">whysd999@163.com</a>
              </p>
              <p>
                公司地址：
                <a
                  href="http://api.map.baidu.com/geocoder?address=湖北省武汉市江岸区新华路296号IFC金融中心&output=html&src=webapp.baidu.openAPIdemo"
                  target="_blank"
                >湖北省武汉市江岸区新华路296号IFC金融中心41层</a>
              </p>
            </div>
          </div>
        </div>
        <div class="ContactUs_content_bg"></div>
      </div>
    </div>
    <div v-if="tab==2" class="Feedback">
      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">意见反馈</div>
        <div>Feedback</div>
      </div>

      <div class="Feedback_content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          key="ruleForm"
          label-width="100px"
          :inline="true"
          class="demo-ruleForm"
        >
          <el-form-item prop="userType" class="Feedback_content_style">
            <div>
              <span style="color:red">*</span>用户类型
            </div>
            <el-select
              v-model="userTypeValue"
              @change="userTypeChange"
              class="Feedback_content_style2"
            >
              <el-option
                v-for="item in userType"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="feedbackType" class="Feedback_content_style">
            <div>
              <span style="color:red">*</span>反馈类型
            </div>
            <el-select @visible-change="feedbackTypeFocus" v-model="ruleForm.feedbackType" class="Feedback_content_style2">
              <el-option
                v-for="item in typeList"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="unit" class="Feedback_content_style">
            <div>
              <span style="color:red"></span>单位
            </div>
            <el-input v-model="ruleForm.unit" class="Feedback_content_style2"></el-input>
          </el-form-item>

          <el-form-item prop="userPhone" class="Feedback_content_style">
            <div>
              <span style="color:red"></span>电话
            </div>
            <el-input v-model="ruleForm.userPhone" class="Feedback_content_style2"></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <div>
              <span style="color:red"></span>邮箱
            </div>
            <el-input v-model="ruleForm.email" class="Feedback_content_style2"></el-input>
          </el-form-item>

          <div>
            <el-form-item prop="feedbackContent" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>反馈内容
              </div>
              <el-input
                type="textarea"
                :rows="10"
                class="textarea_style"
                v-model="ruleForm.feedbackContent"
                maxlength="2000"
              ></el-input>
            </el-form-item>
          </div>

          <div style="position: relative;">
            <el-form-item class="Feedback_content_style">
              <div>
                <span style="color:red"></span>上传图片
              </div>
              <el-upload
                :action="uploadUrl"
                list-type="picture-card"
                :file-list="fileList"
                :on-success="uploadSuccess"
                :on-error="uploadError"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt />
              </el-dialog>
            </el-form-item>
            <button type="button" @click="submitForm('ruleForm')" class="Submit">提交</button>
          </div>
        </el-form>
      </div>
    </div>
    <div v-if="tab==3" class="ProjectCooperation">
      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">项目合作</div>
        <div>Project Cooperation</div>
      </div>

      <div class="Feedback_content">
        <el-form
          :model="ruleForm2"
          :rules="rules2"
          ref="ruleForm2"
          key="ruleForm2"
          label-width="100px"
          :inline="true"
          class="demo-ruleForm"
        >
          <el-form-item prop="organizationName" class="Feedback_content_style">
            <div>
              <span style="color:red">*</span>机构名称
            </div>
            <el-input v-model="ruleForm2.organizationName" class="Feedback_content_style2"></el-input>
          </el-form-item>

          <el-form-item prop="organizationNature" class="Feedback_content_style">
            <div>
              <span style="color:red">*</span>机构性质
            </div>
            <el-input v-model="ruleForm2.organizationNature" class="Feedback_content_style2"></el-input>
          </el-form-item>
          <el-form-item prop="organizationSite">
            <div>
              <span style="color:red">*</span>机构地址
            </div>
            <el-input v-model="ruleForm2.organizationSite" class="Feedback_content_style2"></el-input>
          </el-form-item>
          <div>
            <el-form-item prop="organizationPhone" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>机构联系电话
              </div>
              <el-input v-model="ruleForm2.organizationPhone" class="Feedback_content_style2"></el-input>
            </el-form-item>
            <el-form-item prop="organizationEmail" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>机构邮箱
              </div>
              <el-input v-model="ruleForm2.organizationEmail" class="Feedback_content_style2"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="organizationPresentation" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>机构简介
              </div>
              <el-input
                type="textarea"
                :rows="10"
                class="textarea_style"
                v-model="ruleForm2.organizationPresentation"
                maxlength="2000"
              ></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="projectName" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>合作项目名称
              </div>
              <el-input v-model="ruleForm2.projectName" class="Feedback_content_style2"></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="projectPresentation" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>合作项目简介
              </div>
              <el-input
                type="textarea"
                :rows="10"
                class="textarea_style"
                v-model="ruleForm2.projectPresentation"
                maxlength="2000"
              ></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="projectMan" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>项目联系人
              </div>
              <el-input v-model="ruleForm2.projectMan" class="Feedback_content_style2"></el-input>
            </el-form-item>

            <el-form-item prop="projectPhone" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>项目联系人电话
              </div>
              <el-input v-model="ruleForm2.projectPhone" class="Feedback_content_style2"></el-input>
            </el-form-item>
            <el-form-item prop="smsAuthCode">
              <div>
                <span style="color:red">*</span>验证码
              </div>
              <el-input
                v-model="ruleForm2.smsAuthCode"
                class="Feedback_content_style2"
                style="position: relative;"
              ></el-input>
              <el-button
                class="verificationBtn"
                v-show="sendCode"
                @click="ObtainCode()"
                type="text"
              >获取验证码</el-button>
              <p class="verificationBtn" v-show="!sendCode">{{authTime}}s</p>
            </el-form-item>

            <div style="text-align: center;">
              <button type="button" class="Submit2" @click="submitForm2('ruleForm2')">提交</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div v-if="tab==4" class="Suppliers">
      <div class="courseDevelopment_title">
        <div class="courseDevelopment_title_style">供应商报名</div>
        <div>Suppliers</div>
      </div>

      <div class="Feedback_content">
        <el-form
          :model="ruleForm3"
          :rules="rules3"
          ref="ruleForm3"
          key="ruleForm3"
          label-width="100px"
          :inline="true"
          class="demo-ruleForm"
        >
          <el-form-item prop="supplierType" class="Feedback_content_style">
            <div>
              <span style="color:red">*</span>供应商类型
            </div>
            <el-select v-model="ruleForm3.supplierType" class="Feedback_content_style2">
              <el-option
                v-for="item in typeList2"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="supplierName">
            <div>
              <span style="color:red">*</span>供应商单位名称
            </div>
            <el-input v-model="ruleForm3.supplierName" class="Feedback_content_style3"></el-input>
          </el-form-item>

          <div>
            <el-form-item prop="supplierPresentation" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>供应商单位简介
              </div>
              <el-input
                type="textarea"
                :rows="10"
                class="textarea_style"
                v-model="ruleForm3.supplierPresentation"
                maxlength="2000"
              ></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="supplierProject" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>供应商成功项目简介
              </div>
              <el-input
                type="textarea"
                :rows="10"
                class="textarea_style"
                v-model="ruleForm3.supplierProject"
                maxlength="2000"
              ></el-input>
            </el-form-item>
          </div>

          <div>
            <el-form-item prop="linkmanName" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>联系人称呼
              </div>
              <el-input v-model="ruleForm3.linkmanName" class="Feedback_content_style2"></el-input>
            </el-form-item>

            <el-form-item prop="linkmanPhone" class="Feedback_content_style">
              <div>
                <span style="color:red">*</span>联系人电话
              </div>
              <el-input v-model="ruleForm3.linkmanPhone" class="Feedback_content_style2"></el-input>
            </el-form-item>
            <el-form-item prop="smsAuthCode">
              <div>
                <span style="color:red">*</span>验证码
              </div>
              <el-input
                v-model="ruleForm3.smsAuthCode"
                class="Feedback_content_style2"
                style="position: relative;"
              ></el-input>
              <el-button
                class="verificationBtn"
                v-show="sendCode2"
                @click="ObtainCode2()"
                type="text"
              >获取验证码</el-button>
              <p class="verificationBtn" v-show="!sendCode2">{{authTime2}}s</p>
            </el-form-item>
            <div style="text-align: center;">
              <button type="button" class="Submit2" @click="submitForm3('ruleForm3')">提交</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Message } from "element-ui";
import { _debounce } from "@/utils/gloal";
import {
  getReList,
  submitFb,
  submitPc,
  sendAuthCode,
  submitSu,
  valuesByCategory,
  suSendAuthCode,
  valuesByPid
} from "@/api/api";
export default {
  name: "TalentConcept",
  metaInfo: {
    title: "联系我们", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content:
          "亿事达招聘,招聘,亿事达联系方式,供应商报名,意见反馈,商业项目合作"
      },
      {
        name: "description",
        content: "亿事达集团联系方式"
      }
    ]
  },
  data() {
    return {
      tab: 0,
      ruleForm: {
        userType: "",
        feedbackType: "",
        unit: "",
        userPhone: "",
        email: "",
        feedbackContent: "",
        feedbackImg: []
      },
      userTypeValue: "",

      ruleForm2: {
        organizationName: "",
        organizationNature: "",
        organizationSite: "",
        organizationPhone: "",
        organizationEmail: "",
        organizationPresentation: "",
        projectName: "",
        projectPresentation: "",
        projectMan: "",
        projectPhone: "",
        smsAuthCode: ""
      },
      ruleForm3: {
        supplierType: "",
        supplierName: "",
        supplierProject: "",
        supplierPresentation: "",
        linkmanName: "",
        linkmanPhone: "",
        smsAuthCode: ""
      },
      userType: [],
      typeList: [],
      typeList2: [],
      dialogImageUrl: "",
      dialogVisible: false,
      reData: [],
      RecruitmentItem: [],
      radioRecruitment: null,
      centerDialogVisible: false,
      fileList: [],
      sendCode: true, // 控制发送验证码按钮显示
      authTime: 0, // 倒计时
      sendCode2: true, // 控制发送验证码按钮显示
      authTime2: 0, // 倒计时
      disabled: false,
      typeId: "",
      rules: {
        userType: [
          { required: true, message: "请选择用户类型", trigger: "blur" }
        ],
        feedbackType: [
          { required: true, message: "请选择反馈类型", trigger: "blur" }
        ],
        userPhone: [
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "change"
          }
        ],
        feedbackContent: [
          { required: true, message: "请输入反馈内容", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ]
      },
      rules2: {
        organizationName: [
          { required: true, message: "请填写机构名称", trigger: "blur" }
        ],
        organizationNature: [
          { required: true, message: "请填写机构性质", trigger: "blur" }
        ],
        organizationSite: [
          { required: true, message: "请填写机构地址", trigger: "blur" }
        ],
        organizationPhone: [
          { required: true, message: "请填写机构联系电话", trigger: "blur" },
          {
            pattern: /^\d+$/,
            message: "请输入正确的号码格式",
            trigger: "change"
          }
        ],
        organizationPresentation: [
          { required: true, message: "请填写机构简介", trigger: "blur" }
        ],
        projectName: [
          { required: true, message: "请填写合作项目名称", trigger: "blur" }
        ],
        projectPresentation: [
          { required: true, message: "请填写合作项目简介", trigger: "blur" }
        ],
        projectMan: [
          { required: true, message: "请填写项目联系人", trigger: "blur" }
        ],
        projectPhone: [
          { required: true, message: "请填写项目联系人电话", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "change"
          }
        ],
        smsAuthCode: [
          { required: true, message: "请填写验证码", trigger: "blur" }
        ],
        organizationEmail: [
          { required: true, message: "请填写邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ]
      },
      rules3: {
        supplierType: [
          { required: true, message: "请选择供应商类型", trigger: "blur" }
        ],
        supplierName: [
          { required: true, message: "请填写供应商名称", trigger: "blur" }
        ],
        supplierProject: [
          {
            required: true,
            message: "请填写供应商成功项目简介",
            trigger: "blur"
          }
        ],
        supplierPresentation: [
          { required: true, message: "请填写施工单位简介", trigger: "blur" }
        ],
        linkmanName: [
          { required: true, message: "请填写联系人姓名", trigger: "blur" }
        ],
        linkmanPhone: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的号码格式",
            trigger: "change"
          }
        ],
        smsAuthCode: [
          { required: true, message: "请填写验证码", trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    "ruleForm.userType": function() {
      this.valuesByPid();
    }
  },
  created() {},
  mounted() {
    // 监听鼠标滚动事件
    this.$store.commit("updateTab", 5);
    if (this.$route.query.subtab) {
      this.tab = this.$route.query.subtab;
    }
    this.getReList();
    this.valuesByCategory();
	this.supplierTypeByType()
  },
  methods: {
    // 判断元素距离窗口的位置
    isElemVisible(el) {
      var rect = el.getBoundingClientRect();
      var elemTop = rect.top + 200; // 200 = buffer
      var elemBottom = rect.bottom;
      return elemTop < window.innerHeight && elemBottom >= 0;
    },

    getReList() {
      getReList()
        .then(res => {
          console.log(res.data);
          this.reData = res.data.retData;
        })
        .catch(err => {
          console.log(err);
        });
    },
    userTypeChange(e) {
      console.log(e);
      var that = this;
      that.userType.map(item => {
        if (item.id == e) {
          var id = item.code;
          that.ruleForm.userType = id;
        }
      });
    },
    valuesByCategory() {
      const body = {
        category: "FEEDBACK-USER-TYPE"
      };
      valuesByCategory(body)
        .then(res => {
          console.log(res.data);
          this.userType = res.data.retData;
        })
        .catch(err => {
          console.log(err);
        });
    },
    valuesByPid() {
      const body = {
        pid: this.userTypeValue
      };
      valuesByPid(body)
        .then(res => {
          console.log(res.data);
          this.typeList = res.data.retData;
        })
        .catch(err => {
          console.log(err);
        });
    },
	supplierTypeByType() {
	  const body = {
	    category: "SUPPLIER-TYPE"
	  };
	  valuesByCategory(body)
	    .then(res => {
	      this.typeList2 = res.data.retData;
		  if (this.$route.query.suppType) {
		  	this.ruleForm3.supplierType=this.$route.query.suppType
		  }
	    })
	    .catch(err => {
	      console.log(err);
	    });
	},
	feedbackTypeFocus(){
		if(!this.userTypeValue){
			Message.error("请先选择用户类型！");
		}
	},
    openRecruitment(row) {
      this.centerDialogVisible = true;
      this.RecruitmentItem = row.relations;
    },
    radioChange(e) {
      console.log(e);
    },
    togoLink() {
      this.RecruitmentItem.map(item => {
        if (this.radioRecruitment == item.platformId) {
          if (
            item.link.substr(0, 7).toLowerCase() == "http://" ||
            item.link.substr(0, 8).toLowerCase() == "https://"
          ) {
            window.open(item.link);
          } else {
            window.open("Https://" + item.link);
          }
        }
      });
    },

    ObtainCode() {
      if (this.ruleForm2.projectPhone == "") {
        Message.error("请填写手机号");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(this.ruleForm2.projectPhone)) {
        Message.error("手机号输入有误");
        return;
      }

      let body = {
        projectPhone: this.ruleForm2.projectPhone
      };

      sendAuthCode(body)
        .then(res => {
          console.log(res.data);
          if (res.data.retCode == 200) {
            Message.success("发送成功！");
            this.sendCode = false; // 控制显示隐藏
            this.authTime = 120;
            let timeInt = setInterval(() => {
              this.authTime--;
              if (this.authTime <= 0) {
                this.sendCode = true;
                window.clearInterval(timeInt);
              }
            }, 1000);
          } else {
            Message.error(res.data.retMsg);
          }
        })
        .catch(err => {
          console.log(err);
          Message.error("发送失败！");
        });
    },
    ObtainCode2() {
      if (this.ruleForm3.linkmanPhone == "") {
        Message.error("请填写手机号");
        return;
      } else if (!/^1[3456789]\d{9}$/.test(this.ruleForm3.linkmanPhone)) {
        Message.error("手机号输入有误");
        return;
      }
      let body = {
        linkmanPhone: this.ruleForm3.linkmanPhone
      };

      suSendAuthCode(body)
        .then(res => {
          console.log(res.data);
          if (res.data.retCode == 200) {
            Message.success("发送成功！");
            this.sendCode2 = false; // 控制显示隐藏
            this.authTime2 = 120;
            let timeInt = setInterval(() => {
              this.authTime2--;
              if (this.authTime2 <= 0) {
                this.sendCode2 = true;
                window.clearInterval(timeInt);
              }
            }, 1000);
          } else {
            Message.error(res.data.retMsg);
          }
        })
        .catch(err => {
          console.log(err);
          Message.error("发送失败！");
        });
    },

    choosebtn(tab) {
      this.tab = tab;
      if (tab == 2) {
        console.log(1111);
        // this.$refs["ruleForm2"].clearValidate();
        //this.$refs["ruleForm3"].clearValidate();
      }
      if (tab == 3) {
        console.log(2222);
        // this.$refs["ruleForm"].clearValidate();
        // this.$refs["ruleForm3"].clearValidate();
      }
      if (tab == 4) {
        console.log(3333);
        // this.$refs["ruleForm"].clearValidate();
        // this.$refs["ruleForm2"].clearValidate();
      }
    },
    submitForm: _debounce(function(formName) {
      this.$refs[formName].validate(valid => {
        this.ruleForm.feedbackImg = this.ruleForm.feedbackImg.toString();
        if (valid) {
          submitFb(this.ruleForm).then(res => {
            console.log(res.data);
            if (res.data.retCode == 200) {
              Message.success("发送成功！");
              this.ruleForm = {
                feedbackType: "",
                userType: "",
                userPhone: "",
                email: "",
                feedbackContent: "",
                feedbackImg: []
              };
              this.fileList = [];
              this.userTypeValue = "";
              this.typeList = [];
            } else {
              Message.error(res.data.retMsg);
            }
          });
        } else {
          Message.error("提交失败！");
          return false;
        }
      });
    }, 1000),
    submitForm2: _debounce(function(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          submitPc(this.ruleForm2).then(res => {
            console.log(res.data);
            if (res.data.retCode == 200) {
              Message.success("发送成功！");
              this.ruleForm2 = {
                organizationName: "",
                organizationNature: "",
                organizationSite: "",
                organizationPhone: "",
                organizationEmail: "",
                organizationPresentation: "",
                projectName: "",
                projectPresentation: "",
                projectMan: "",
                projectPhone: "",
                smsAuthCode: ""
              };
              this.sendCode = true; // 控制显示隐藏
              this.authTime = 0;
            } else {
              Message.error(res.data.retMsg);
            }
          });
        } else {
          Message.error("提交失败！");
          return false;
        }
      });
    }, 1000),
    submitForm3: _debounce(function(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          submitSu(this.ruleForm3).then(res => {
            console.log(res.data);
            if (res.data.retCode == 200) {
              Message.success("发送成功！");
              this.ruleForm3 = {
                supplierType: "",
                supplierName: "",
                supplierProject: "",
                supplierPresentation: "",
                linkmanName: "",
                linkmanPhone: "",
                smsAuthCode: ""
              };
              this.sendCode2 = true; // 控制显示隐藏
              this.authTime2 = 0;
            } else {
              Message.error(res.data.retMsg);
            }
          });
        } else {
          Message.error("提交失败！");
          return false;
        }
      });
    }, 1000),

    handleRemove(file, fileList) {
      console.log(fileList);
      let imgList = [];
      fileList.map(item => {
        imgList.push(item.url);
      });
      this.ruleForm.feedbackImg = imgList;
    },
    uploadSuccess(res) {
      console.log(res);
      this.ruleForm.feedbackImg.push(res.retData.url);
    },
    uploadError(err) {
      console.log(err);
      this.$message({
        message: "上传失败",
        type: "error",
        duration: 1000
      });
    },
    handleExceed(files, fileList) {
      this.$message({
        message: "最多只能上传" + this.maxCount + "张图片",
        type: "warning",
        duration: 1000
      });
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    }
  }
};
</script>
<style lang="scss">
$vm_w_base: 1920;
$vm_h_base: 1080;
@function vm_w($px) {
  @return ($px / 1920) * 100vw;
}
.TalentConcept {
  .bgimg {
    width: 100%;
    position: relative;
  }
  .bgimg img {
    width: 100%;
  }
  .aboutUs_tab {
    width: vm_w(1200);
    height: vm_w(64);
    background-color: #ffffff;
    position: absolute;
    bottom: vm_w(-32);
    z-index: 10;
    left: calc(50% - #{vm_w(600)});
    left: -webkit-calc(50% - #{vm_w(600)});
    left: -moz-calc(50% - #{vm_w(600)});
    left: -ms-calc(50% - #{vm_w(600)});
    left: -o-calc(50% - #{vm_w(600)});
    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.08);
  }
  .TalentConcept_content_style {
    margin-top: vm_w(32);
  }
  .aboutUs_tab_item {
    width: 20%;
    height: 100%;
    line-height: vm_w(64);
    display: inline-block;
    vertical-align: top;
    text-align: center;
    cursor: pointer;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #666666;
  }
  .aboutUs_tab_item2 {
    width: 20%;
    height: 100%;
    line-height: vm_w(64);
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: $chooseColor;
  }
  .courseDevelopment_title {
    width: 100%;
    text-align: center;
    font-size: vm_w(32);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
  }
  .TalentConcept_content {
    width: vm_w(1200);
    margin: 0 auto;
    padding-bottom: vm_w(66);
  }
  .TalentConcept_item {
    width: vm_w(188);
    height: vm_w(136);
    background: #f9f9f9;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    text-align: center;
    display: table;
  }
  .el-dialog__header {
    padding: vm_w(10);
    background-color: #f1f1f1;
    font-size: vm_w(24);
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    color: #333333;
    .el-dialog__headerbtn {
      top: vm_w(15);
    }
  }
  .TalentConcept_item_title {
    font-size: vm_w(18);
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 600;
    color: #333333;
  }
  .TalentConcept_item_text {
    margin-top: vm_w(10);
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Normal;
    color: #666666;
    line-height: vm_w(24);
  }
  .TalentConcept_content_item2 {
    width: 100%;
    cursor: pointer;
  }
  .TalentConcept_content_item2:hover .TalentConcept_content_item2_line {
    background: $chooseColor;
  }
  .TalentConcept_content_item2:hover .TalentConcept_content_item2_content_btn {
    background: $chooseColor;
    opacity: 1;
    filter: alpha(opacity = 100);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 100);
  }
  .TalentConcept_content_item2:hover
    .TalentConcept_content_item2_content_style
    span {
    opacity: 0;
    filter: alpha(opacity = 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
  }
  .TalentConcept_content_item2_line {
    width: vm_w(4);
    height: vm_w(54);
    display: inline-block;
    background: #dbdbdb;
    vertical-align: middle;
  }
  .radioImg {
    width: vm_w(98);
  }
  .TalentConcept_content_item2_content {
    display: inline-block;
    width: 90%;
    margin-left: vm_w(32);
    vertical-align: middle;
  }
  .TalentConcept_content_item2_content_style_content {
    display: inline-block;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .TalentConcept_content_item2_content_btn {
    width: vm_w(96);
    height: vm_w(32);
    line-height: vm_w(32);
    background: $chooseColor;
    border-radius: 4px;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-left: vm_w(95);
    opacity: 0;
    filter: alpha(opacity = 0);
    filter: progid:DXImageTransform.Microsoft.Alpha(style = 0, opacity = 0);
  }
  .radioBtn {
    width: vm_w(236);
    height: vm_w(40);
    line-height: vm_w(40);
    background: $chooseColor;
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #ffffff;
    border: none;
    cursor: pointer;
  }

  .ContactUs_bgimg {
    width: 100%;
    height: vm_w(650);
    position: relative;
    margin-top: vm_w(32);
  }
  .ContactUs_bgimg img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
    top: 0px;
  }
  .ContactUs_Popconfirm {
    width: vm_w(224);
    height: vm_w(108);
    z-index: 10;
    position: absolute;
    top: 0px;
    background: $chooseColor;
    border-radius: 4px;
  }
  .bubble-box {
    // background: $chooseColor;
    width: vm_w(224);
    z-index: 10;
    position: absolute;
    top: vm_w(146);
    left: vm_w(815);
  }
  .bubble-box .wrap {
    background: $chooseColor;
    /* 修正IE6 */
    _position: relative;
    _z-index: 10;
  }
  .wrap {
    & p {
      margin-top: vm_w(16);
    }
  }
  /* arrow-effect */
  // .arrow-left{ border-left:20px solid #FFF; border-top:20px solid #EEE; margin-top:20px;}
  // .arrow-right{ border-right:20px solid #FFF; border-top:20px solid #EEE;}
  // .arrow-top{ border-left:20px solid #EEE; border-top:20px solid #FFF; margin-left:20px;}
  .arrow-bottom {
    border-left: vm_w(25) solid $chooseColor;
    border-bottom: vm_w(20) solid rgba(255, 255, 255, 0);
    margin-left: vm_w(20);
  }
  .arrow-left .wrap,
  .arrow-right .wrap {
    width: vm_w(180);
    padding: vm_w(12) vm_w(10) vm_w(12) vm_w(10);
    margin-top: vm_w(-40);
  }
  .arrow-top,
  .arrow-bottom {
    width: vm_w(224);
  }
  .arrow-top .wrap,
  .arrow-bottom .wrap {
    width: vm_w(224);
    padding: vm_w(16);
    margin-left: vm_w(-75);
    line-height: vm_w(24);
    border-radius: 4px;
    box-shadow: 0px 3px 6px 0px rgba(10, 24, 71, 0.16),
      0px 3px 6px 0px rgba(10, 24, 71, 0.16);
    font-size: vm_w(16);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #ffffff;
  }
  .ContactUs_content {
    width: vm_w(445);
    height: vm_w(274);
    background-color: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: vm_w(118);
    left: vm_w(1078);
    z-index: 10;
  }
  .ContactUs_content_bg {
    width: vm_w(445);
    height: vm_w(275);
    position: absolute;
    top: vm_w(152);
    left: vm_w(1111);
    background-color: $chooseColor;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    z-index: 9;
  }
  .ContactUs_content_content {
    width: vm_w(389);
    margin: vm_w(40) vm_w(32);
  }
  .TalentConcept_content_style2 {
    margin-left: vm_w(63);
  }
  .ContactUs_content_title {
    font-size: vm_w(18);
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 600;
    color: $chooseColor;
    line-height: vm_w(24);
  }
  .ContactUs_content_title2 {
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Medium;
    font-weight: 500;
    color: $chooseColor;
    line-height: vm_w(24);
  }
  .ContactUs_content_text {
    margin-top: vm_w(30);
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #333333;
    line-height: vm_w(24);
  }
  .TalentConcept_content_item2_content_style {
    font-size: vm_w(24);
    position: relative;
    margin-bottom: vm_w(8);
    & span {
      font-size: vm_w(14);
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #666666;
      line-height: vm_w(24);
      position: absolute;
      right: vm_w(32);
    }
  }

  .TalentConcept_content_item2_content_style2 {
    font-size: vm_w(16);
  }
  .textarea_style {
    width: vm_w(1200) !important;
  }
  .Feedback_content {
    width: vm_w(1200);
    margin: 0 auto;
    margin-top: vm_w(25);
    padding-bottom: vm_w(45);
  }
  .el-upload.el-upload--picture-card {
    width: vm_w(274);
    height: vm_w(274);
    background: #f5f5f5;
    border: none !important;
  }
  .el-upload--picture-card i {
    line-height: vm_w(274);
    font-size: vm_w(173) !important;
    color: #fff !important;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: vm_w(274) !important;
    height: vm_w(274) !important;
  }
  .Submit {
    width: vm_w(96);
    height: vm_w(40);
    line-height: vm_w(40);
    color: #fff;
    background: $chooseColor;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
  }
  .Feedback_content_style {
    margin-right: vm_w(28) !important;
    .el-dialog__header {
      padding: vm_w(0);
      .el-dialog__headerbtn {
      }
    }
  }
  .Feedback_content_style2 {
    width: vm_w(376) !important;
  }
  .Feedback_content_style3 {
    width: vm_w(580) !important;
  }

  .Submit2 {
    width: vm_w(236);
    height: vm_w(40);
    line-height: vm_w(40);
    color: #fff;
    background: $chooseColor;
    cursor: pointer;
    border-radius: 4px;
    border: none;
  }
  .el-input--suffix .el-input__inner {
    background: #f5f5f5;
    border: none !important;
  }
  .el-input__inner {
    background: #f5f5f5 !important;
    border: none !important;
  }

  .el-textarea__inner {
    background: #f5f5f5 !important;
    border: none !important;
  }
  .verificationBtn {
    color: $chooseColor;
    font-size: vm_w(14);
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    position: absolute;
    right: vm_w(8);
    top: 50%;
  }
  .courseDevelopment_title_style {
    margin-top: vm_w(93);
    margin-bottom: vm_w(10);
  }
}
</style>